import React, { useState } from 'react'
import banner from "../../images/Stairlifts/staitlift-banner.webp"
import aboutUs from "../../images/Stairlifts/stairlift-about-us-main.webp"
import cert1 from "../../images/Stairlifts/iso-certificate.jpg"
import cert2 from "../../images/Stairlifts/iso-certificate.jpg"
function Stairlift() {

  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

    return (
        <div className='flex flex-col  text-justify justify-center items-center gap-[50px] md:text-[12px]'>
            <div className='w-[100%] h-[50vh] md:h-[20vh] relative'>
            <img className='w-[100%] h-[100%] object-cover' src={banner} alt='stairlifts'/>
            <div className='absolute top-[0] flex justify-center items-center bg-[#0000008e] w-[100%] h-[100%] '>
            <h1 className=' text-[4rem] w-[100%] text-center text-white md:text-[30px] '>Accessibility</h1>
            </div>
            </div>
            <div className=' w-[70%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] flex flex-col gap-[50px]'>
            <div className=' w-[100%] flex lg:flex-col justify-between items-center'>


            <div className=' w-[45%] lg:w-[100%] flex flex-col  gap-[20px]  '>
      <h1 className='text-[30px]  md:text-[20px]  w-[fit-content]'>About Us</h1>
           <p> albert massaad misr® is proud to offer a range of chairlifts designed to provide freedom and independence to those with mobility challenges. Chairlifts are essential for individuals who face difficulties navigating stairs, offering a safe and reliable solution to maintain autonomy within their homes. albert massaad misr® Chairlifts are crafted with precision and attention to detail, ensuring smooth and secure transport between floors. These products help enhance the quality of life, allowing users to move freely and confidently in their own space without needing constant assistance.<br></br><br></br> We understand the importance of maintaining independence while ensuring safety and comfort. Our chairlifts are not just about accessibility; they represent a commitment to improving daily living, ensuring that users can stay in the homes they love without limitations. With innovative technology and sleek designs, our chairlifts blend seamlessly into any environment, providing both function and style.</p>
           
            </div> 
        
         <div className=' w-[50%] lg:w-[100%] xl:mt-[2%] md:hidden'>
         <img className='w-[100%] h-[100%] object-contain' src={aboutUs} alt='stairlifts-aboutus'/>
         </div>
    
           </div>  
          
               
           <div className="w-[100%] flex lg:flex-col  justify-between items-start gap-[20px] pb-[5%]">
            <div className='w-[45%] lg:w-[100%]  flex flex-col gap-[20px]'>
                <h1 className="text-[30px]  md:text-[20px]  w-[fit-content]">Quality</h1>
                <p>
                  albert massaad misr operates a quality management system that is ISO 9001: 2015 compliant. ISO 9001:2015 is a globally implemented standard, aimed at satisfying quality requirements and enhancing customer satisfaction in supplier-customer relationships.
                </p>
            </div>
      
            <div className="lg:p-[2%] md:flex-wrap md:justify-center xl:gap-[20px] w-[50%] lg:w-[100%] flex justify-between items-start mt-[2%]">
        <div className="h-[100%] flex justify-between flex-col items-start">
          <div className="h-[40px] lg:h-[35px] bg-[#0B3A4F] px-[2%] w-[100%] text-white">
            <p className="text-[15px] lg:text-[12px]">QUALITY MANAGEMENT</p>
            <p className="text-[10px]">ISO 9001:2015</p>
          </div>
          <img
            className="mt-[10px]  h-[350px] md:h-[250px] lg:h-[350px] xl:h-[auto]  object-contain cursor-pointer"
            src={cert1}
            alt="cert-1"
            onClick={() => openModal(cert1)} // Opens the modal with this image
          />
        </div>
        <div className="h-[100%] flex justify-between flex-col">
        <div className="h-[40px] lg:h-[35px] bg-[#0B3A4F] px-[2%] w-[100%] text-white">
            <p className="text-[15px] lg:text-[12px]">QUALITY MANAGEMENT</p>
            <p className="text-[10px]">ISO 14001:2015</p>
          </div>
          <img
            className="mt-[10px]  h-[350px] md:h-[250px] lg:h-[350px] xl:h-[auto]  object-contain cursor-pointer"
            src={cert2}
            alt="cert-2"
            onClick={() => openModal(cert2)} // Opens the modal with this image
          />
        </div>
            </div>

      {/* Modal */}
      {modalImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center z-50"
          onClick={closeModal} // Closes the modal when clicking on the gray background
        >
          <img
            className="w-[50%] xl:w-[80%] h-[100%] object-contain"
            src={modalImage}
            alt="Full Screen View"
          />
        </div>
      )}
         </div>
        
         </div>
    
        </div>
      )
}

export default Stairlift