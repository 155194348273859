import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import slide1 from "../../images/Stairlifts/stair-lift-banner.webp"
import slide2 from "../../images/Home/Slider/slide-2.jpg"

import './styles.css';


import { Autoplay, Pagination } from 'swiper/modules';

function Slider() {
  return (
    <div  className='md:h-[50vh] h-[85vh]'>
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
     
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={slide1} alt='slide-1'></img>
      </SwiperSlide>
      <SwiperSlide> <img src={slide2} alt='slide-2'></img></SwiperSlide>
      {/* <SwiperSlide> <img src={slide3} alt='slide-2'></img></SwiperSlide> */}
     </Swiper>
  </div>
);
}

export default Slider