 

import flowX from "../../images/Stairlifts/Flow X_Main 8.webp"
import flowX1 from "../../images/Stairlifts/FlowX/Flow X_Detail-1.webp"
import flowX2 from "../../images/Stairlifts/FlowX/Flow X_Detail-2.webp" 
import flowX3 from "../../images/Stairlifts/FlowX/Flow X_Detail-3.webp"
import flowX4 from "../../images/Stairlifts/FlowX/Flow X_Detail-4.webp"
import flowX5 from "../../images/Stairlifts/FlowX/Flow X_Detail-5.webp"
import flowX6 from "../../images/Stairlifts/FlowX/FlowX_Main_Detail-6.webp"
import homeglideStraight from "../../images/Stairlifts/straight-homeglide-main.webp"
import straightHomeglide1 from"../../images/Stairlifts/straight-stairlift-homeglide/Seat Belt_Detail.webp"
import straightHomeglide2 from"../../images/Stairlifts/straight-stairlift-homeglide/Controls_Detail.webp"
import straightHomeglide3 from"../../images/Stairlifts/straight-stairlift-homeglide/Mid_Travel_Model.webp"
import straightHomeglide4 from"../../images/Stairlifts/straight-stairlift-homeglide/design.webp"
import straightHomeglide5 from"../../images/Stairlifts/straight-stairlift-homeglide/Handle-Alarm.webp"
import straightHomeglide6 from"../../images/Stairlifts/straight-stairlift-homeglide/Rail_Lift_InMotion_v2.webp"
import outdoor from "../../images/Stairlifts/stairlift-outdoor_3.webp"
import outdoor1 from "../../images/Stairlifts/Outdoors/Outdoor-remote.webp"
import outdoor2 from "../../images/Stairlifts/Outdoors/Access_BDD_Outdoor.webp"
import outdoor3 from "../../images/Stairlifts/Outdoors/secure-key.webp"
import outdoor4 from "../../images/Stairlifts/Outdoors/Access_BDD_Outdoor1.webp"
import outdoor5 from "../../images/Stairlifts/Outdoors/Outdoor-all-season.webp"
import outdoor6 from "../../images/Stairlifts/Outdoors/Outdoor-joyStick.webp"
import supraLiftMain from "../../images/Stairlifts/platform lifts supra/supra-lifts.webp"
import supra4 from "../../images/Stairlifts/platform lifts supra/Detail2.webp"
import supra3 from "../../images/Stairlifts/platform lifts supra/ramp-supra.webp"
import supra2 from "../../images/Stairlifts/platform lifts supra/IP55-package.webp"
import supra5 from "../../images/Stairlifts/platform lifts supra/foldable.webp"
import supra6 from "../../images/Stairlifts/platform lifts supra/Detail4.webp"
import supra1 from "../../images/Stairlifts/platform lifts supra/control.webp"
import supraLinea from "../../images/Stairlifts/supra-linea-main.webp"
import supraLinea1 from "../../images/Stairlifts/platform lifts supra/Linea-Ramp.PNG"
import alturaPlatinum from "../../images/Stairlifts/altura-platinum/altura-platinum-main.webp"
import platinum1 from "../../images/Stairlifts/altura-platinum/platimum-1.webp"
import platinum2 from "../../images/Stairlifts/altura-platinum/platinum-2.webp"
import platinum3 from "../../images/Stairlifts/altura-platinum/platinum-3.webp"
import platinum4 from "../../images/Stairlifts/altura-platinum/platinum-4.webp"
 const stairliftData = [
  {
    Link:"curved-stairlifts-flow-x",
    title: "Curved Stairlifts Flow X",
    sectionTitle:"It's All About You",
    sectionSubtitle:"When you choose the Flow X stairlift, you ensure the best fit for your home.",
    description: [
      {subtitle:"Designed for seamless movement, the Flow X provides outstanding levels of comfort, confidence and craftsmanship."},
      {p1:"The Flow X is designed to fit on narrow staircases, whether they are straight or curved. Fitted to your staircase and not your wall, the Flow X is installed quickly and efficiently, with minimum disruption to your home and blending into your home seamlessly."},
      {p2:"No matter if you have a narrow, steep, or intricately curved staircase, the Flow X stairlift can be customised to provide a smooth and safe ride, enhancing the accessibility and comfort of your home."},
    ],
    image:flowX,
    sections: [
  
      {
        title: "Joystick",
        content: "With a comfortable soft-touch grip, the integrated joystick is designed for you to fold away neatly in the armrest when not in use.",
      image:flowX2},
      {
        title: "Seatbelt",
        content: "Flow X’s one-handed operation seatbelt and wrap-around armrests keep you secure throughout. Having a seatbelt detection system as a standard feature ensures that you are properly secured before starting your journey.",
      image:flowX1},
      {
        title: "Call Device",
        content: "Stay connected with friends and family with up to three different telephone numbers contactable directly from your stairlift.",
      image:flowX4,},
      {
        title: "ASL Technology",
        content: `Our Advanced Swivel and Levelling technology (ASL) allows it to be fitted on staircases as narrow as 610mm. This feature, unique to Flow X, enables your stairlift to rotate while in motion, making it one of the most versatile stairlifts in the world.`,
      image:flowX6,},
      {
        title: "Ergonomic armrests",
        content: `Flow X’s innovative armrests provide several key benefits. They have an ergonomically-tapered form, designed to give you extra support both when standing and sitting. And thanks to the unique open-access armrest position, transferring to a stairlift has never been easier.`,
      image:flowX5,},
      {
        title: "Single rail design",
        content: `Even in cases where the staircase is narrow and steep, with a single discreet rail, the Flow X can be installed closer to the step and on either side of the staircase. Your stairlift will be less obtrusive, and your staircase remains accessible for other family members in your home.`,
      image:flowX3,},
    ],
  },
  {
    Link:"straight-stairlift-homeglide",
    title: "Straight Stairlift HomeGlide",
    sectionTitle:"It's All About You",
    sectionSubtitle:"When you choose the HomeGlide stairlift, you ensure an ideal solution for your staircase.",
    description: [
      {subtitle:"The ultimate marriage of design and functionality for any straight staircase."},
      {p1:"Imagine moving freely and comfortably around your home without worrying about stairs. Our range of straight stairlifts makes this possible, offering you independence and peace of mind."},
      {p2:"The HomeGlide stairlift delivers a solution for all straight staircase requirements and budgets. Designed for effortless simplicity with superior comfort and value."},
    ],
    image:homeglideStraight,
    sections: [
  
      {
        title: "Slim Rail",
        content: "The aluminium rail has a slim design giving a modern and clean look. The HomeGlide is fitted onto your staircase and not your wall. This allows the lift to be installed quickly, efficiently and with minimal disruption to your home.",
      image:straightHomeglide3},
      {
        title: "Seatbelt",
        content: "A retractable seatbelt keeps you secure while using your HomeGlide stairlift. The integrated sensor ensures the seatbelt is fastened securely before travel, and the stainless steel buckle is designed to be operated with just one hand.",
      image:straightHomeglide1},
      {
        title: "Joystick",
        content: "With a comfortable soft-touch grip, the integrated joystick is user-friendly and requires minimal effort to take you up and down your staircase with ease.",
      image:straightHomeglide2,},
      {
        title: "Folding Rail",
        content: `If you have an obstruction at the foot of the staircase, you can choose to have an automatically powered hinge installed. Once you have used your stairlift the lower section of the rail will fold away, leaving the bottom of your staircase clear.`,
      image:straightHomeglide6,},
      {
        title: "Call Device",
        content: `Our optional call device ensures you stay connected with friends and family, with up to three different telephone numbers stored and contactable directly from your stairlift.`,
      image:straightHomeglide5,},
      {
        title: "Discreet design",
        content: `Engineered with modern manufacturing techniques and technology, the HomeGlide stairlift range can be fitted to almost all straight staircases as narrow as 740mm (29”).`,
      image:straightHomeglide4,},
    ],
  },
  {
    Link:"outdoor-stairlifts-homeglide",
    title: "Outdoor Stairlifts HomeGlide ",
    sectionTitle:"It's All About You",
    sectionSubtitle:"Designed with your confidence and comfort in mind",
    description: [
      {subtitle:"With HomeGlide Outdoor you can enjoy your home throughout the year."},
      {p1:"With its modern, ergonomic seat design and slim aluminium rail, the HomeGlide Outdoor makes a discreet addition to your garden. Specially designed to provide reliable mobility whenever you need it, it is the perfect solution for anyone with a straight outdoor staircase who cannot confidently use the stairs."},
      {p2:"Whether you need to access your property from the front due to living on a hill or enjoy the garden accessible via a staircase, the HomeGlide outdoor stairlift can make your home fully accessible both indoors and out. Thanks to the swivel seat, you can get on and off your stairlift at the top of your stairs with ease. The Access BDD stairlift comes equipped with multiple safety features to ensure the protection and peace of mind."},
    ],
    image:outdoor,
    sections: [
  
      {
        title: "Easy-to-use joystick",
        content: "Enjoy smooth and intuitive control with HomeGlide’s ergonomic joystick. Its soft-touch grip ensures effortless navigation up and down stairs, empowering you to move with confidence and comfort.",
      image:outdoor6},
      {
        title: "Compact design",
        content: "Don’t compromise on the appearance of your garden and have the freedom to move around easily with HomeGlide. Its compact and unobtrusive design adds elegance to your outdoor space. The soft, curved shapes and foldable structure of HomeGlide requires minimal space.",
      image:outdoor2},
      {
        title: "Secure access with a key lock",
        content: "Ensure complete control over who operates your stairlift. The HomeGlide Outdoor comes equipped with a keylock feature that prevents unintended usage by children or unauthorised people in public settings. Guaranteeing safety and security, giving you peace of mind.",
      image:outdoor3,},
      {
        title: "Safe access with manual swivel",
        content: `Experience maximum safety and convenience with the HomeGlide manual swivel function. Using the manual swivel lever at the landing turns the chair 70° towards the staircase landing and enables a safe exit from the chair.`,
      image:outdoor4,},
      {
        title: "Remote controls (indoor)",
        content: `Remote control handsets are always included to enable you to call and park your lift without effort. With an intuitive modern design and a neutral colour palette, they sit discreetly on the wall inside your home without intrusion.`,
      image:outdoor1,},
      {
        title: "Enjoy year-round access",
        content: `The HomeGlide Outdoor lift is certified for use between -10°C to +50°C. Its additional cover protects against rain, dust, and sunlight, ensuring safe and dependable mobility all year round. You so can rely on your HomeGlide to provide safe and reliable mobility whenever you need it.`,
      image:outdoor5,},
    ],
  },
  {
    Link:"platform-lifts-supra",
    title: "Platform Lifts Supra ",
    sectionTitle:"Tailored to your needs",
    sectionSubtitle:"When you choose the Supra platform lift, you ensure the best solution for your accessibility requirements.",
    description: [
      {subtitle:"Supra is designed for use on any type of staircase: curved, straight with a change of gradient, multiple landings and spiral configurations."},
      {p1:"Supra’s dual rail concept is robust in design and doubles up as a handrail, creating optimal use of space. Special attention has been paid to safety and quality. The Supra lift has been tested to TÜV standards and is CE-certified."},
      {p2:"They ensure that individuals with mobility challenges can navigate their environment with ease and independence. Our comprehensive range of platform lifts includes commercial, home, and wheelchair lifts, each designed to meet specific needs and requirements."},
    ],
    image:supraLiftMain,
    sections: [
  
      {
        title: "On-board Controls",
        content: "The on-board controls make it simple to operate the Supra and Supra Linea. The ergonomic keys and buttons are shaped so that they can be operated with minimal effort while their design remains aesthetically pleasing.",
      image:supra1},
      {
        title: "IP55 package",
        content: "The Supra have an extended use of anodised aluminium to provide you with a durable product which can withstand. When not in use, IP55 versions of the Supra are supplied with a fitted showerproof protective covering.",
      image:supra2},
      {
        title: "90° Access Ramp",
        content: "When the platform lift arrives at either the top or bottom landing the leading access ramp opens automatically, allowing the user to get on or get off the platform with minimal effort.",
      image:supra3,},
      {
        title: "Compact and Discreet",
        content: `Due to their slim profile the Supra take up
        little space on the staircase when not in use. The choice of parking options and platform sizes makes them ideal for busy buildings where space is at a premium.`,
      image:supra4,},
      {
        title: "Folding Seat",
        content: `With fully automatic folding platforms, a wide choice of platform sizes and intermediate stops, the Supra are suitable for installation into almost every type of building.A folding seat is also available for non-wheelchair users who wish to sit on the platform during travel.`,
      image:supra5,},
      {
        title: "Control Station",
        content: `The call stations are placed at each end of the
        staircase. In public places we recommend the call
        stations with either a joystick (shown here) or with
        buttons. They are normally fitted to the wall but
        when there is no wall nearby a call stanchion can be supplied.`,
      image:supra6,},
    ],
  },
  {
    Link:"platform-lifts-supra-linea",
    title: "Platform Lifts Supra Linea ",
    sectionTitle:"Tailored to your needs",
    sectionSubtitle:"When you choose the Supra Linea platform lift, you ensure the best solution for your accessibility requirements.",
    description: [
      {subtitle:"The Supra Linea platform lift is designed for use on a straight staircase with a constant gradient."},
      {p1:"Platform lifts are essential for enhancing accessibility in homes and commercial spaces. They ensure that individuals with mobility challenges can navigate their environment with ease and independence."},
      {p2:"The Supra Linea has an extended use of anodised aluminium to provide a durable product that can withstand rust, which is ideal for outdoor installations."},
    ],
    image:supraLinea,
    sections: [
  
      {
        title: "On-board Controls",
        content: "The Supra Linea features ergonomic keys and buttons that are designed for minimal effort while remaining aesthetically pleasing, making it simple to operate.",
      image:supra1},
      {
        title: "IP55 package",
        content: "With the addition of the IP55 package, the Supra Linea is the perfect solution for outdoor use or places with high condensation levels. Additionally, when not in use, the IP55 version of the Supra Linea is supplied with a fitted showerproof protective covering.",
      image:supra2},
      {
        title: "90° Access Ramp",
        content: "When the platform lift arrives at the top or bottom landing, the leading access ramp opens automatically, allowing the user to get on or off the platform with minimal effort.",
      image:supraLinea1},
      {
        title: "Compact and Discreet",
        content: `Due to its slim profile, the Supra Linea takes up very little space on the staircase when not in use. The choice of parking options and platform sizes makes them ideal for busy buildings where space is at a premium.`,
      image:supra4,},
      {
        title: "Folding Seat",
        content: `With fully automatic folding platforms, a wide choice of platform sizes and intermediate stops, the Supra Linea is suitable for installation into almost every type of building. A folding seat is also available for non-wheelchair users who wish to sit on the platform during travel.`,
      image:supra5,},
      {
        title: "Control Station",
        content: `Call stations are located at both ends of the staircase. We suggest using call stations with either a joystick (as shown in the picture) or buttons for public places. Control stations are typically mounted on the wall, but a call stanchion can be provided when no wall is available nearby.`,
      image:supra6,},
    ],
  },
  {
    Link:"home-lifts-altura-platinum",
    title: "Home Lifts Altura Platinum",
      sectionTitle:"A platform lift for every situation",
    sectionSubtitle:"Altura Platinum can be customised with a wide choice of colours and finishes.",
    description: [
      {subtitle:"Experience luxury with the Altura Platinum, a versatile lift for a wide range of applications."},
      {p1:"Experience luxury with the Altura home lift range. We supply home lifts in a wide range of sizes and configurations home lifts that offer elegant styling to match your home."},
      {p2:"The Altura Platinum is driven by proven and reliable hydraulic technology. Available in a wide range of sizes and configurations, the Altura Platinum is a highly adaptable lift that can be used in a wide range of public and private environments, indoors and outdoors. It is available with its own metallic shaft or can be installed in a masonry shaft."},
    ],
    image:alturaPlatinum,
    sections: [
  
     
      {
        title: "Smooth from start to finish",
        content: "All Altura Platinum lifts are fitted with a soft start and stop device as standard, guaranteeing you an exceptionally smooth ride from the moment you press the floor button on the control panel. For your safety, cabins with open sides are fitted with a hold-to-run control button that must be kept permanently pressed during the ride, whilst the fully enclosed cabin has one-touch controls.",
      image:platinum1},
      {
        title: "Design your lift",
        content: `Choose from the wide selection of Altura cabin finishes to create a lift that suits your needs and matches your surroundings. The minimalist Simplicity range offers discreet coordination with its environment and is a good choice with public buildings, whilst the elegance of the Deluxe range will suit any stylish interior.`,
      image:platinum2,},
      {
        title: "Easy to install",
        content: `The Altura Platinum is designed to be installed in just a few days, and when installed in its own structural shaft, requires minimal construction work. It is offered with eight standard plan sizes or can be custom-made to your required dimensions. The lift runs on standard mains electricity. Altura lifts are fully compliant with all applicable safety standards.`,
      image:platinum3,},
      {
        title: "Safety and comfort",
        content: `All Altura Platinum lifts are fitted with a soft start and stop device, guaranteeing you an exceptionally smooth ride every time. The edges of open-sided cabins are fitted with photocells that will immediately stop the lift if any obstruction extends beyond the edge of the platform, keeping passengers safe at all times.`,
      image:platinum4,},
    ],
  }, 
];
  export default stairliftData

  

  
   
  
  




  

