import React from "react";
import { FiPhone } from "react-icons/fi";
import in2info from "../images/Home/in2info-logo.png"
import { MdOutlineMail,MdOutlineWhatsapp  } from "react-icons/md";
import Logo from "../images/Stairlifts/updated-logo-white.png";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const scrollToContactUs = (e) => {
    e.preventDefault();
    
    const contactSection = document.getElementById('contact-us');
  
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    } else {

      navigate('/', { state: { scrollToContact: true } });
    }
  };
  

  return (
    <div className="w-[100%] bg-[#0B3A4F] flex justify-center items-center flex-col">
    <div className=" w-[68%] 2xl:w-[73%] xl:w-[82%] lg:w-[80%] md:w-[85%] sm:w-[90%] flex  lg:flex-col lg:gap-[20px] py-[2%] lg:py-[3%] ">
     <div className="flex flex-col gap-[5%] text-white">
        <Link to="/" className="w-[220px] ml-[-5%] lg:ml-[-1%] mb-[5%]  lg:w-[180px]">
             <img className="w-[100%] object-cover" src={Logo} alt="Logo" />
        </Link>
        <div className="flex flex-col gap-[15px]">
          <div>
          <p className=" text-[11px]">Factory No.24C, </p>
        <p className=" text-[11px]">Third Industrial Zone,</p>
        <p className=" text-[11px]">Southern Region,</p>
        <p className=" text-[11px]">10th of Ramadan City - Sharqia, Egypt.</p>

          </div>
          <div className="text-right mr-[8%] lg:w-[fit-content]">
          <p className=" text-[11px]">مصنع رقم٢٤ج</p>
        <p className=" text-[11px]">المنطقة الصناعية الثالثة</p>
        <p className=" text-[11px]">مجمع الصناعات الصغيرة والمتوسطة</p>
        <p className=" text-[11px]">المنطقة الجنوبية، مدينة العاشر من رمضان - الشرقية، مصر</p>

          </div>
        </div>
       
        
    </div>

      <div className=" flex w-[70%] lg:flex-col text-[12px] lg:gap-[20px] justify-evenly">
        <div className=" text-white gap-[3px] flex flex-col">
          <p className="text-[18px] lg:text-[15px]">Links</p>
          <Link to="/">Home</Link>

          <Link to="/shelving-systems">Shelving Systems</Link>
          <Link to="/accessibility">Accessibility</Link>

          <a href="#contact-us" onClick={scrollToContactUs}>Contact Us</a>
        </div>
        <div className=" text-white gap-[3px] flex flex-col text-[12px]">
          <p className="text-[18px] lg:text-[15px]">More Info</p>
          <div className="text-white flex items-center gap-[2%] text-[12px]">
           <MdOutlineWhatsapp className="w-[18px] h-[18px]" />
           <a href="tel:+201090155103" className="text-white">+20 109 0155103</a>
         </div>
         <div className="text-white flex items-center gap-[2%] text-[12px]">
    <MdOutlineMail className="w-[18px] h-[18px]" />
    <a href="mailto:info@ammisr.com" className="text-white">info@ammisr.com</a>
         </div>
         
        </div>
      </div>
     
    </div>
    <div className=" w-[70%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] pl-[1%] flex bg-[#0B3A4F] lg:flex-row text-white gap-[5%] lg:justify-between  lg:gap-2 py-[0.4%]">
          <p className="text-left text-[12px] sm:text-[8px]">© 2024 albert massaad misr</p>
          <div className="flex justify-center items-center gap-[10px]">
            <p className="text-center text-[12px] sm:text-[8px] ">SITE<span className="font-bold">MANAGER</span> V4.0</p>
            <a href="http://www.in2info.com/" target="_blank" rel="noopener noreferrer" className="hover:opacity-75 transition-opacity duration-300">
              <img src={in2info} className="w-[70px]" alt="in2infoLogo" />
            </a>
          </div>
        </div>
    </div>
  );
}

export default Footer;
