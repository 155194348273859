import React, { useState, useEffect } from 'react';
import stairliftData from '../StairLift/stairliftdata';
import { useParams } from 'react-router-dom';

function StairliftProducts() {
    const { Link } = useParams();
    const [stairlift, setStairlift] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const fetchedStairlift = stairliftData.find(item => item.Link === Link);
        setTimeout(() => {
            setStairlift(fetchedStairlift);
            setIsLoading(false);
        }, 1000);
    }, [Link]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="loader"></div>
            </div>
        );
    }

    if (!stairlift) {
        return <div className="py-[3%]">Stairlift not found</div>;
    }

    return (
        <div className="flex bg-[white] justify-center items-center flex-col gap-[10%]">
            <div className='w-[100%] bg-[#EEEEEE] p-[0.8%] flex justify-center'>
            <div className="w-[70%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] text-justify md:text-justify  flex justify-between lg:flex-col lg:items-center">
                <div className="w-[45%] lg:w-[100%] 2xl:w-[50%] py-[5%]">
                    <h1 className="text-[30px]  md:text-[22px]  w-[fit-content] mb-[3%]">
                        {stairlift.title}
                    </h1>
                    {stairlift?.description?.map((item, index) => (
                        <div key={index} className="flex flex-col gap-[5px] w-[95%] leading-6">
                            <p className="text-[20px] md:text-[15px] xl:text-[20px] font-[500]">{item.subtitle}</p>
                            <p className="text-[15px] md:text-[12px]">{item.p1}</p>
                            <p className="text-[15px] md:text-[12px]">{item.p2}</p>
                        </div>
                    ))}
                </div>
                <div className="min-w-[50%] md:hidden w-[50%] lg:w-[100%] flex justify-center">
                    <img src={stairlift.image} alt="" className="object-contain" />
                </div>
            </div>
            </div>
            <div className="flex justify-center w-[70%] sm:w-[90%] 2xl:w-[75%] xl:w-[84%] items-center  flex-col mb-[5%]">
                <div className="flex w-[100%] justify-center text-center flex-col items-center mt-[50px]">
                    <h1 className="text-[30px] md:text-[25px] w-[fit-content]">{stairlift.sectionTitle}</h1>
                    <p className="text-[15px] w-[fit-content] md:text-center">{stairlift.sectionSubtitle}</p>
                </div>
                <div className="flex mt-[50px] w-[100%] h-[auto] md:gap-[30px] md:pb-[8%] flex-wrap justify-between items-center">
                    {stairlift.sections.map((info, index) => (
                        <div
                            key={index}
                            className={`  ${stairlift.Link==="home-lifts-altura-platinum"?" w-[30vw] 2xl:w-[35vw] xl:w-[40vw] lg:w-[100%] lg:h-[auto] xl:h-[550px] lg:mb-[20px]":"w-[20vw] gap-[10px] md:w-[100%] xl:w-[28vw] lg:w-[40vw]  2xl:w-[25vw]"} mb-[1%] h-[500px] 
                           md:h-[auto]  sm:w-[100%] p-[1%] text-justify  flex flex-col`}
                        >
                            <div className="w-[100%] bg-[gray]">
                                <img src={info.image} alt="" className={`w-[100%] ${stairlift.Link==="home-lifts-altura-platinum"?"h-[300px] lg:h-[auto]":"h-[250px] md:h-[auto]"}`} />
                            </div>
                            <div className="w-[100%]">
                                <p className="text-[25px] md:text-[15px] md:font-bold my-[2%]">{info.title}</p>
                                <p className=" md:text-[12px]">{info.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StairliftProducts;
